import React, { useState, useEffect } from "react";
import axios from "axios";
import Admin_search from "./Admin_search";
import Addmin_Modal from "./Admin_Modal";
import Modal_order from "../Marketing/Modal_order";
import Admin_Add_BOM from "./Admin_Add_BOM";

const Admin_BOM_List = () => {
  const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortedData, setSortedData] = useState([]);
  const [editableRowId, setEditableRowId] = useState(null);

  const openAddOrderModal = () => {
    setAddOrderModalOpen(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpen(false);
    // Clear existing Job IDs when modal is closed
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    sortData();
  }, [data, searchTerm, selectedCategory]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-bom-list"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortData = () => {
    const sorted = data.slice().sort((a, b) => {
      // Assuming 'recieve_order_date' is the property representing the date
      return new Date(b.part_no) - new Date(a.part_no);
    });
    setSortedData(sorted);

    setCurrentPage(1); // Reset current page when sorting
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = sortedData.filter((item) => {
    if (selectedCategory === "all") {
      return Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return (
        (selectedCategory === "model" &&
          item.customer &&
          item.customer.model
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partName" &&
          item.customer &&
          item.customer.part_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partNo" &&
          item.customer &&
          item.customer.part_no
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      );
    }
  });
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (name, value) => {
    if (name === "selectedCategory") {
      setSelectedCategory(value);
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1); // Reset current page when search term changes
  };

  const renderTables = () => {
    const groupedItems = {};

    data.forEach((item) => {
      if (!groupedItems[item.part_no]) {
        groupedItems[item.part_no] = [];
      }
      groupedItems[item.part_no].push(item);
    });

    return Object.keys(groupedItems).map((partNo, index) => {
      const items = groupedItems[partNo];
      return (
        <div key={index}>
          <h2>Part No : {partNo}</h2>
          <table className="data-table">
            <thead>
              <tr>
                <th>Part SUB No.</th>
                <th>Part Name</th>
                <th>Quantity.</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, itemIndex) => (
                <tr key={itemIndex}>
                  <td>{item.sub_no}</td>
                  <td>{item.part_name}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="search_render">
        <div className="stick_Add_Bom">
          <button onClick={openAddOrderModal}>ADD BOM</button>
          <p />
        </div>
        {renderTables()}

        <Modal_order
          isOpen={isAddOrderModalOpen}
          onClose={closeAddOrderModal}
          title="ADD BOM ORDER"
        >
          <Admin_Add_BOM />
        </Modal_order>
      </div>
    </div>
  );
};
export default Admin_BOM_List;
