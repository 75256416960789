import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

function Store_menu() {
  return (
    <div className="LeftMenu">
      <ul>
        <Link to="/HUB" className="menu-link">
          <li>HOME</li>
        </Link>
        <Link to="/Store" className="menu-link">
          <li>STORE</li>
        </Link>
        <p />
        <Link to="/Withdraw" className="menu-link">
          <li>WITHDRAW LOG</li>
        </Link>
        <Link to="/Recieve" className="menu-link">
          <li>RECIEVE LOG</li>
        </Link>
      </ul>
    </div>
  );
}

export default Store_menu;
