import React, { useState } from "react";
import Planning_menu from "../../Component/Planning/Planning_Menu";
import Planning_FG_Table from "../../Component/Planning/Planning_FG_Table";
const Planning_FG = () => {
  return (
    <div style={{ display: "flex" }}>
      <Planning_menu style={{ flex: "0 0 auto" }} />{" "}
      {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>FINISH GOOD</h1>
        <div className="table_background">
          <Planning_FG_Table />
        </div>
      </div>
    </div>
  );
};

export default Planning_FG;
