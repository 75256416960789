import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal_order from "../../Component/Marketing/Modal_order";
import Planning_Order_Mat from "../../Component/Planning/Planning_Order_Mat";
import Planning_search from "./Planning_search";
import Planning_Assign_Assy_order from "./Planning_Assign_Assy";

function Planning_Assign_Assy() {
  const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);
  const [existingJobIds, setExistingJobIds] = useState([]);

  const openAddOrderModal = () => {
    setAddOrderModalOpen(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpen(false);
    // Clear existing Job IDs when modal is closed
    setExistingJobIds([]);
  };

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    sortData();
  }, [data, searchTerm, selectedCategory]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-plan-assign-assy-list"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortData = () => {
    const sorted = data.slice().sort((a, b) => {
      // Ensure job_no is not null or undefined
      if (!a.job_no || !b.job_no) return 0;

      // Extract year, month, day, and sequential number from job_no
      const regex = /JobAssy(\d{2})(\d{2})(\d{2})(\d{3})/;
      const matchA = a.job_no.match(regex);
      const matchB = b.job_no.match(regex);

      // Ensure match is not null or undefined
      if (!matchA || !matchB) return 0;

      const [, yearA, monthA, dayA, seqA] = matchA;
      const [, yearB, monthB, dayB, seqB] = matchB;

      // Compare year, month, day, and sequential number
      if (yearA !== yearB) return parseInt(yearB) - parseInt(yearA); // Compare year in descending order
      if (monthA !== monthB) return parseInt(monthB) - parseInt(monthA); // Compare month in descending order
      if (dayA !== dayB) return parseInt(dayB) - parseInt(dayA); // Compare day in descending order
      return parseInt(seqB) - parseInt(seqA); // Compare sequential number in descending order
    });

    setSortedData(sorted);
    setCurrentPage(1); // Reset current page when sorting
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = sortedData.filter((item) => {
    if (selectedCategory === "all") {
      return Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return (
        (selectedCategory === "model" &&
          item.customer &&
          item.customer.model
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partName" &&
          item.customer &&
          item.customer.part_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partNo" &&
          item.customer &&
          item.customer.part_no
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      );
    }
  });
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (name, value) => {
    if (name === "selectedCategory") {
      setSelectedCategory(value);
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1); // Reset current page when search term changes
  };

  // Function to handle updating the remark for a specific item

  // Function to set the editable row ID when a row is clicked

  return (
    <div>
      <div style={{ flex: "1" }}>
        <div className="table_background">
          <div className="search_render">
            <Planning_search
              selectedCategory={selectedCategory}
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
            />
          </div>
          <table className="data-table">
            <thead>
              <tr>
                <th>Job No.</th>
                <th>Part No.</th>
                <th>Size</th>
                <th>Process</th>
                <th>Machine</th>
                <th>Tact Time</th>
                <th>Man Power</th>
                <th>Quantity</th>
                <th>Date</th>
                <th>Remark</th>
                {/* Add additional table headers as needed */}
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.id}>
                  <td>{item.job_no}</td>
                  <td>{item.part_no}</td>
                  <td>{item.size}</td>
                  <td>{item.process}</td>
                  <td>{item.machine}</td>
                  <td>{item.tact_time}</td>
                  <td>{item.man_power}</td>
                  <td>{item.quantity}</td>
                  <td>{item.addsign_at}</td>
                  <td>{item.remarks}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="page_action">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="prev_order"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="next_order"
            >
              Next
            </button>
          </div>
        </div>
        <button onClick={openAddOrderModal}>Assign</button>
        <p />
      </div>
      <Modal_order
        isOpen={isAddOrderModalOpen}
        onClose={closeAddOrderModal}
        title="ADD ORDER"
      >
        <Planning_Assign_Assy_order />
      </Modal_order>
    </div>
  );
}

export default Planning_Assign_Assy;
