import React, { useState } from "react";
import axios from "axios"; // Import Axios if not already imported

const Admin_Add_BOM = () => {
  const [part_no, setPartNo] = useState("");
  const [part_name, setPartName] = useState("");
  const [sub_no, setSubNo] = useState("");
  const [quantity, setQuantity] = useState("");
  const [created_by, setCreateby] = useState("");
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!part_no || !sub_no) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("part_no", part_no);
      formData.append("part_name", part_name);
      formData.append("sub_no", sub_no);
      formData.append("quantity", quantity);
      formData.append("created_by", created_by);

      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-bom",
        formData
      );
      console.log("Status:", response.data.message);
      setIsOrderSubmitted(true);
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <div>
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Part No."
            type="text"
            value={part_no}
            onChange={(e) => setPartNo(e.target.value)}
          />
        </label>{" "}
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Part Name"
            type="text"
            value={part_name}
            onChange={(e) => setPartName(e.target.value)}
          />
        </label>
        <p />
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Part Sub No."
            type="text"
            value={sub_no}
            onChange={(e) => setSubNo(e.target.value)}
          />
        </label>
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Quantity."
            type="text"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </label>
        {/*}
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter user."
            type="text"
            value={created_by}
            onChange={(e) => setCreateby(e.target.value)}
          />
        </label>*/}
      </div>
      <button type="submit">Submit Order</button>
      {isOrderSubmitted && <p>Order submitted successfully!</p>}
    </form>
  );
};

export default Admin_Add_BOM;
