import React from "react";
import "./SearchResultsPopup.css";

const SearchResultsPopup = ({ results, onClose }) => {
  // Calculate total quantities for each part_no
  const totalQuantities = {};
  results.forEach((result) => {
    const { part_no, quantity } = result;
    totalQuantities[part_no] =
      (totalQuantities[part_no] || 0) + parseInt(quantity, 10);
  });

  // Calculate unique part numbers
  const uniquePartNos = [...new Set(results.map((result) => result.part_no))];

  return (
    <div className="modal">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times; {/* Close icon */}
        </button>
        <ul className="results-list">
          {uniquePartNos.map((part_no, index) => (
            <li key={index} className="result-item">
              <div className="result-details">
                <p className="part-no">Part No: {part_no}</p>
                {/* Display total quantity for each part_no */}
                <p className="total-quantity">
                  Total Quantity: {totalQuantities[part_no]}
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SearchResultsPopup;
