import React from "react";
import { useState } from "react";
import MenuMarketing from "../../Component/Marketing/Menu_Marketing";
import DataTable from "../../Component/Marketing/Marketing_table";
import Modal from "../../Component/Marketing/Modal";
import TotalSellModal from "../../Component/Marketing/Total_sell";

const Report_Marketing = () => {
  const [isTotalSellOpen, setTotalSellOpen] = useState(false);
  const [isExportModalOpen, setExportModalOpen] = useState(false);

  const openTotalSell = () => {
    setTotalSellOpen(true);
  };

  const closeTotalSell = () => {
    setTotalSellOpen(false);
  };

  const openExportModal = () => {
    setExportModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <MenuMarketing style={{ flex: "0 0 auto" }} />{" "}
      <div style={{ flex: "1" }}>
        <h1>REPORT MARKETING</h1>
        <div className="table_background">
          <DataTable />
        </div>

        <button onClick={openExportModal} className="">
          <h7> Export</h7>
        </button>
        <button onClick={openTotalSell}>
          <h7> Check Total Sell</h7>
        </button>
      </div>
      <Modal
        isOpen={isExportModalOpen}
        onClose={closeExportModal}
        title="Export"
      >
        {/* Content for the Export modal */}
       
      </Modal>
      <Modal
        isOpen={isTotalSellOpen}
        onClose={closeTotalSell}
        title="Total Sell"
      >
        <TotalSellModal />
      </Modal>
    </div>
  );
};

export default Report_Marketing;
