import React, { useState } from "react";
import axios from "axios"; // Import Axios if not already imported

const Planning_Order_Mat = () => {
  const [part_no, setPartNo] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [maker, setMaker] = useState("");
  const [recived_Date, setReceiveDate] = useState("");
  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(recived_Date);
    if (!part_no || !quantity || !recived_Date || !size) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("part_no", part_no);
      formData.append("size", size);
      formData.append("quantity", quantity);
      formData.append("maker", maker);
      formData.append("recived_date", recived_Date);
      formData.append("remark", remark);

      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-bill-material",
        formData
      );
      console.log("Status:", response.data.message);
      setIsOrderSubmitted(true);
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  const handleDateChange = (event) => {
    setReceiveDate(event.target.value);
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <div>
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Part No."
            type="text"
            value={part_no}
            onChange={(e) => setPartNo(e.target.value)}
            list="partNumbers" // Referencing the datalist
          />
        </label>
        <datalist id="partNumbers">
          {/* Define your valid options as <option> elements */}
          <option value="14919 4JL0A" />
          <option value="14919 4KD0A" />
          <option value="16422 4JA0A" />
          <option value="14919 4JL0A" />
          <option value="16422 4JL9E" />
          <option value="16422 4KD0A" />
          <option value="25238 4KJ2A" />
          <option value="16422 4KD9E" />
          <option value="16423 4JA0A" />
          <option value="16423 4JL0A" />
          <option value="16423 4KD0A" />
          <option value="16426 4JL0A" />
          <option value="20851 4JM0A" />
          {/* Add more options as needed */}
        </datalist>

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Size"
            type="text"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            list="size" // Referencing the datalist
          />
        </label>
        <datalist id="size">
          {/* Define your valid options as <option> elements */}
          <option value="0.8x95x147" />
          <option value="0.8x92xCOIL" />
          <option value="2.6x320xCOIL" />
          <option value="2.6x162x274" />
          <option value="2.0x150xCOIL" />
          <option value="2.6x250xCOIL" />
          <option value="1.6x435xCOIL" />
          <option value="2.0x110xCOIL" />
          <option value="2.6x120xCOIL" />
          <option value="2.6x335xCOIL" />
          <option value="2.6x328xCOIL" />
          <option value="2.6x120xCOIL" />
          {/* Add more options as needed */}
        </datalist>
        <p />
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Quantity"
            type="text"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </label>

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Maker"
            type="text"
            value={maker}
            onChange={(e) => setMaker(e.target.value)}
          />
        </label>
        <p />
        {/*
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Status"
            type="text"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />
        </label>*/}

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Remark"
            type="text"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </label>
        <p />
        <label htmlFor="recived_Date">Receive Date:</label>
        <input
          className="box_sign_input_order"
          type="date"
          name="recived_date"
          value={recived_Date}
          onChange={handleDateChange}
        />
      </div>
      <button type="submit">Submit Order</button>
      {isOrderSubmitted && <p>Order submitted successfully!</p>}
    </form>
  );
};

export default Planning_Order_Mat;
