// PostDataComponent.js
import React, { useState } from "react";
import axios from "axios";

function Admin_User_Add() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isActive, setIsActive] = useState("");
  const [accountUser, setAccountUser] = useState("");
  const [accountPass, setAccountPass] = useState("");
  const [accountType, setAccountType] = useState("");
  const [account_role, setAccountRole] = useState("");
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phoneNumber ||
      !isActive ||
      !accountUser ||
      !accountType ||
      !accountPass ||
      !account_role
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("email", email);
      formData.append("phone_number", phoneNumber);
      formData.append("is_active", isActive);
      formData.append("account_user", accountUser);
      formData.append("account_pass", accountPass);
      formData.append("account_type", accountType);
      formData.append("account_role", account_role);

      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-account",
        formData
      );
      console.log("Status:", response.data.message);
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          First Name :
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Last Name :
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </label>
        <br />
        <label>
          Email :
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </label>
        <br />
        <label>
          Phone Number :
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </label>
        <br />
        <label>
          Is Active :
          <input
            type="text"
            value={isActive}
            onChange={(e) => setIsActive(e.target.value)}
          />
        </label>
        <br />
        <label>
          Account User :
          <input
            type="text"
            value={accountUser}
            onChange={(e) => setAccountUser(e.target.value)}
          />
        </label>
        <br />
        <label>
          Account Password :
          <input
            type="password"
            value={accountPass}
            onChange={(e) => setAccountPass(e.target.value)}
          />
        </label>
        <br />
        <label>
          Account Type :
          <select
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          >
            <option value="">Select Account Type</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
            <option value="superadmin">Super Admin</option>
          </select>
        </label>
        <br />
        <label>
          Account Role :
          <select
            value={account_role}
            onChange={(e) => setAccountRole(e.target.value)}
          >
            <option value="">Select Account Type</option>
            <option value="1">Super Admin</option>
            <option value="2">Admin</option>
            <option value="3">Marketing</option>
            <option value="4">Planning</option>
            <option value="5">Store</option>
            <option value="6">Press</option>
            <option value="8">Assy</option>
            <option value="7">Finish Goods</option>
          </select>
        </label>
        <br />
        <button type="submit">Submit</button>
        {isOrderSubmitted && <p>Order submitted successfully!</p>}
      </form>
    </div>
  );
}
export default Admin_User_Add;
