import React, { useState, useEffect } from "react";
import Planning_menu from "../../Component/Planning/Planning_Menu";
import Planning_Assign_Assy from "../../Component/Planning/Planning_Assy";
import Planning_Assign_Press from "../../Component/Planning/Planning_Press";

const Planning_Plan_Assign = () => {
  // Initialize state from localStorage or default to null
  const [selectedComponent, setSelectedComponent] = useState(() => {
    return localStorage.getItem("selectedComponent") || "";
  });

  // Update localStorage whenever selectedComponent changes
  useEffect(() => {
    localStorage.setItem("selectedComponent", selectedComponent);
  }, [selectedComponent]);

  const handleComponentChange = (event) => {
    setSelectedComponent(event.target.value);
  };

  const renderComponent = () => {
    switch (selectedComponent) {
      case "assy":
        return <Planning_Assign_Assy />;
      case "press":
        return <Planning_Assign_Press />;
      default:
        return null;
    }
  };

  return (
    <div style={{ height: "100vh", overflowY: "auto", display: "flex" }}>
      {/* Set the entire screen to be scrollable */}
      <Planning_menu style={{ flex: "0 0 auto" }} />
      <div style={{ flex: "1", overflowY: "auto" }}>
        {/* Set the content area to be scrollable */}
        <h1>Plan Assign</h1>
        <div>
          <select value={selectedComponent} onChange={handleComponentChange}>
            <option value="">Select Department</option>
            <option value="press">Press</option>
            <option value="assy">Assembly</option>
          </select>
        </div>
        <div
          className="table-container"
          style={{ maxHeight: "calc(110vh - 110px)" }}
        >
          {/* Set a fixed height for the table container */}
          {renderComponent()}
        </div>
      </div>
    </div>
  );
};

export default Planning_Plan_Assign;
