import React, { useState } from "react";
import Assy_joblist from "../../Component/Department/Department_Assy_JobList";
import Assy_menu from "../../Component/Department/Assy_Menu";
const Assy_list = () => {
  return (
    <div style={{ display: "flex" }}>
      <Assy_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>ASSY JOB LIST</h1>
        <div className="table_background">
          <Assy_joblist />
        </div>
      </div>
    </div>
  );
};

export default Assy_list;
