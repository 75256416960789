import React, { useState } from "react";
import axios from "axios";

const PostCustomerData = () => {
  const [partName, setPartName] = useState("");
  const [partNo, setPartNo] = useState("");
  const [model, setModel] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        part_no: partNo,
        customer: {
          part_name: partName,
          model: model,
          destination_country: destinationCountry,
          // Include part_name field in the customer object
          // Include other fields if needed (e.g., model, destination_country)
        },
      };

      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-customer",
        formData
      );
      console.log("Data posted successfully:", response.data);
      // Clear the input fields after successful submission
      setPartNo("");
      setPartName("");
      setModel("");
      setDestinationCountry("");
      // Clear other input fields if needed
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };
  return (
    <div>
      <h2>Post Customer Data</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="partName">Part Name:</label>
        <input
          type="text"
          id="partName"
          value={partName}
          onChange={(e) => setPartName(e.target.value)}
        />
        <label htmlFor="partNo">Part No:</label>
        <input
          type="text"
          id="partNo"
          value={partNo}
          onChange={(e) => setPartNo(e.target.value)}
        />
        <label htmlFor="model">Model:</label>
        <input
          type="text"
          id="model"
          value={model}
          onChange={(e) => setModel(e.target.value)}
        />
        <label htmlFor="destinationCountry">Destination Country:</label>
        <input
          type="text"
          id="destinationCountry"
          value={destinationCountry}
          onChange={(e) => setDestinationCountry(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default PostCustomerData;
