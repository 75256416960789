import React from "react";

const Depart_search = ({ selectedCategory, searchTerm, onSearchChange }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    onSearchChange(selectedCategory, value);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    onSearchChange("selectedCategory", value);
  };

  return (
    <div>
      <select value={selectedCategory} onChange={handleCategoryChange}>
        <option value="all">All</option>
      </select>
      <input
        type="text"
        placeholder={`Search ${
          selectedCategory === "all" ? "All" : selectedCategory
        }`}
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default Depart_search;
