import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

function Assy_menu() {
  return (
    <div className="LeftMenu">
      <ul>
        <Link to="/HUB" className="menu-link">
          <li>HOME</li>
        </Link>
        <p />
        <Link to="/AssyJob" className="menu-link">
          <li>ASSY</li>
        </Link>
        <Link to="/AssyWithdraw" className="menu-link">
          <li>WITHDRAW LOG</li>
        </Link>
        <Link to="/AssyDaily" className="menu-link">
          <li>DAILY PRODUCTION</li>
        </Link>
        <Link to="/AssyReturnMat" className="menu-link">
          <li>MAT RETURN</li>
        </Link>
      </ul>
    </div>
  );
}

export default Assy_menu;
