// Home.js
import React, { useState } from "react";
import Store_menu from "../../Component/Store/Store_Menu";
import Receive_Table from "../../Component/Store/Store_Receive_Table";
import Modal_order from "../../Component/Marketing/Modal_order";
import Receive_log from "../../Component/Store/Store_Receive_Log";

const Store_Recieve = () => {
  const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);
  const [isAddOrderModalLogOpen, setAddOrderModalLogOpen] = useState(false);

  const openAddOrderModal = () => {
    setAddOrderModalOpen(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpen(false);
  };

  const openAddOrderLogModal = () => {
    setAddOrderModalLogOpen(true);
  };

  const closeAddOrderLogModal = () => {
    setAddOrderModalLogOpen(false);
  };
  return (
    <div style={{ display: "flex" }}>
      <Store_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>Recieve Log</h1>
        <div className="table_background">
          <Receive_Table />
        </div>
        <button onClick={openAddOrderModal}>Received Check List</button>
        <Modal_order
          isOpen={isAddOrderModalOpen}
          onClose={closeAddOrderModal}
          title="LOG"
        >
          <Receive_Table />
          <button onClick={openAddOrderLogModal}>ADD LOG</button>
          <Modal_order
            isOpen={isAddOrderModalLogOpen}
            onClose={closeAddOrderLogModal}
            title="ADD LOG"
          >
            <Receive_log />
          </Modal_order>
        </Modal_order>
      </div>
    </div>
  );
};

export default Store_Recieve;
