import React, { useState } from "react";
import Assy_menu from "../../Component/Department/Assy_Menu";
import Assy_Withdraw_Table from "../../Component/Department/Department_Assy_Withdraw";

const Assy_Withdraw_log = () => {
  return (
    <div style={{ display: "flex" }}>
      <Assy_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>ASSY WITHDRAW LOG</h1>
        <div className="table_background">
          <Assy_Withdraw_Table />
        </div>
      </div>
    </div>
  );
};

export default Assy_Withdraw_log;
