// PostDataComponent.js
import React, { useEffect, useState } from "react";
import axios from "axios";

function AxiosComponent() {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://data.b-lost-dev.com/api-test/data-test-get")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Axios error:", error);
      });
  }, []);

  return (
    <div>
      {/* Display data in your component */}
      {data.map((item) => (
        <div key={item.id}>
          <p>ID: {item.id}</p>
          <p>Name: {item.name}</p>
          <p>Email: {item.email}</p>
          <p>Phone: {item.phone}</p>
          {/* Add more properties as needed */}
        </div>
      ))}
    </div>
  );
}
export default AxiosComponent;
