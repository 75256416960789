import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

const ExcelFileUploader = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isValidating, setIsValidating] = useState(false);
  const [validationResults, setValidationResults] = useState([]);
  const [orderIds, setOrderIds] = useState([]);
  const [initialOrderIds, setInitialOrderIds] = useState([]);

  const fetchOrderIds = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-marketing-list"
      );
      const ids = response.data.data.map((item) => item.order_id);
      setInitialOrderIds(ids);
    } catch (error) {
      console.error("Error fetching order IDs:", error);
      setInitialOrderIds([]);
    }
  };

  useEffect(() => {
    fetchOrderIds();
  }, []);

  const generateOrderId = (existingIds = [], recentIds = []) => {
    existingIds = Array.isArray(existingIds) ? existingIds : [];
    recentIds = Array.isArray(recentIds) ? recentIds : [];

    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    let nextSequentialNumber = 1;

    // Combine existingIds and recentIds to check for uniqueness
    const allIds = [...existingIds, ...recentIds];

    let orderId;
    do {
      orderId = `OR${year}${month}${day}${nextSequentialNumber
        .toString()
        .padStart(3, "0")}`;
      nextSequentialNumber++;
    } while (allIds.includes(orderId));

    return orderId;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const sendDataToServer = async (formData) => {
    try {
      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-marketing",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Data uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  const handleFileUpload = async () => {
    setValidationResults([]);
    setIsValidating(true);

    try {
      const reader = new FileReader();
      reader.onload = async function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const results = [];

        for (let i = 1; i < parsedData.length; i++) {
          const row = parsedData[i];
          const partNo = row[0];
          const countryNo = row[1];
          const ranNo = row[2];
          const quantity = row[3];
          const deliveryDateSerial = row[4];
          const formattedDeliveryDate = formatDate(deliveryDateSerial);
          const currentDate = new Date().toLocaleDateString();

          // Generate a unique order ID for each row
          const orderId = generateOrderId(
            [...initialOrderIds],
            [
              ...orderIds,
              ...results.map((result) => result.formData.get("order_id")),
            ]
          );

          const isValidPartNo = await validatePartNumber(partNo);

          const formData = new FormData();
          formData.append("part_no", partNo);
          formData.append("country_no", countryNo);
          formData.append("ran_no", ranNo);
          formData.append("quantity", quantity);
          formData.append("delivery_date", formattedDeliveryDate);
          formData.append("currentDate", currentDate);
          formData.append("order_id", orderId);

          results.push({
            index: i - 1,
            isValidPartNo,
            formData,
          });

          if (isValidPartNo) {
            await sendDataToServer(formData);
          } else {
            console.error(
              "Invalid part number found:",
              formData.get("part_no")
            );
          }
        }

        setValidationResults(results);
      };

      reader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Error processing file:", error);
    } finally {
      setIsValidating(false);
    }
  };

  const formatDate = (serialDate) => {
    const excelDate = new Date(1900, 0, serialDate - 1);
    const year = excelDate.getFullYear();
    const month = (excelDate.getMonth() + 1).toString().padStart(2, "0");
    const day = excelDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const validatePartNumber = useCallback(async (partNo) => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-bom-list"
      );
      console.log("Validation API response:", response.data);
      console.log("Part number being validated:", partNo);

      return (
        Array.isArray(response.data.data) &&
        response.data.data.some((item) => item.part_no === partNo)
      );
    } catch (error) {
      console.error("Error validating part number:", error);
      return false;
    }
  }, []);

  return (
    <div>
      <input type="file" accept=".xls, .xlsx" onChange={handleFileChange} />
      <button onClick={handleFileUpload} disabled={isValidating}>
        {isValidating ? "Validating..." : "Upload Excel File"}
      </button>
      {validationResults.length > 0 && (
        <div>
          <h3>Validation Results:</h3>
          <ul>
            {validationResults.map((result) => (
              <li key={result.index}>
                Row {result.index + 2}:{" "}
                {result.isValidPartNo ? "Valid" : "Invalid"} part number
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ExcelFileUploader;
