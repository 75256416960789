import React, { useEffect, useState } from "react";
import GetDataComponent from "../Component/Fetch_data_component";
import PostDataComponent from "../Component/Post_data";
import Component from "../Component/Component";
import AxiosComponent from "../Component/Axios_test";

const TestAjex = () => {
  return (
    <div>
      {/*<AxiosComponent />*/}
      <Component />
      {/*<GetDataComponent />*/}
      {<PostDataComponent />}
    </div>
  );
};

export default TestAjex;
