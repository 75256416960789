import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

function FG_menu() {
  return (
    <div className="LeftMenu">
      <ul>
        <Link to="/HUB" className="menu-link">
          <li>HOME</li>
        </Link>
        <p />
        <Link to="/FGPage" className="menu-link">
          <li>FINISH GOODS</li>
        </Link>
        <Link to="/FGReceive" className="menu-link">
          <li>RECEIVE GOODS</li>
        </Link>
        <Link to="/FGPlating" className="menu-link">
          <li>PLATING ORDER</li>
        </Link>
        <Link to="/FGMarketing" className="menu-link">
          <li>MARKETING</li>
        </Link>
      </ul>
    </div>
  );
}

export default FG_menu;
