// Home.js
import React from "react";
import Store_menu from "../../Component/Store/Store_Menu";
import Store_table from "../../Component/Store/Store_Table";

const Store = () => {
  return (
    <div style={{ display: "flex" }}>
      <Store_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>Stock Mat</h1>
        <div className="table_background">
          <Store_table />
        </div>
      </div>
    </div>
  );
};

export default Store;
