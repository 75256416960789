import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

function Admin_menu() {
  return (
    <div className="LeftMenu">
      <ul>
        <Link to="/HUB" className="menu-link">
          <li>HOME</li>
        </Link>
        <p />
        {/*<Link to="/Dashboard" className="menu-link">
          <li>DASH BOARD</li>
        </Link>*/}
        <Link to="/UserManage" className="menu-link">
          <li>USER</li>
        </Link>
        <Link to="/BomData" className="menu-link">
          <li>BOM</li>
        </Link>
        <Link to="/ProdData" className="menu-link">
          <li>PRODUCTION</li>
        </Link>
      </ul>
    </div>
  );
}

export default Admin_menu;
