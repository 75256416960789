// Home.js
import React, { useState } from "react";
import Admin_menu from "../../Component/Admin/Admin_menu";
import Admin_user_manage from "../../Component/Admin/Admin_User_Manage";

const User_manage = () => {
  return (
    <div style={{ display: "flex" }}>
      <Admin_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>USER MANAGE</h1>
        <div className="table_background">
          <Admin_user_manage />
        </div>
      </div>
    </div>
  );
};

export default User_manage;
