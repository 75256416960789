import React, { useState } from "react";
import DataTable from "../../Component/Marketing/Marketing_table";
import MenuMarketing from "../../Component/Marketing/Menu_Marketing";
import ExcelFileUploader from "../../Component/Marketing/Excelfile_uploader";
import Modal_order from "../../Component/Marketing/Modal_order";
import Modal from "../../Component/Marketing/Modal";
import OrderForm from "../../Component/Marketing/Addorder_marketing";
import Marketing_export from "../../Component/Marketing/Marketing_Export";
import Marketing_Forcast_Uploader from "../../Component/Marketing/Marketing_Forcast_Uploader";
import Marketing_Forcast_Add from "../../Component/Marketing/Add_order_Forcast";

const Marketing_Forcast_Page = ({ isOpen, onClose, title, children }) => {
  const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);
  const [isExportModalOpen, setExportModalOpen] = useState(false);

  const openAddOrderModal = () => {
    setAddOrderModalOpen(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpen(false);
  };

  const openExportModal = () => {
    setExportModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <MenuMarketing style={{ flex: "0 0 auto" }} />{" "}
      {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>MARKETING FORECAST LIST</h1>
        <div className="table_background">
          <div className="stay_left_upload">
            <Marketing_Forcast_Uploader />
          </div>
          <DataTable />
        </div>
        <div>
          <button onClick={openAddOrderModal}>Add Order</button>
          <button onClick={openExportModal}>Export</button>
        </div>
      </div>
      <Modal_order
        isOpen={isAddOrderModalOpen}
        onClose={closeAddOrderModal}
        title="ADD ORDER"
      >
        <Marketing_Forcast_Add />
      </Modal_order>
      <Modal_order
        isOpen={isExportModalOpen}
        onClose={closeExportModal}
        title="EXPORT ORDER"
      >
        <Marketing_export />
      </Modal_order>
    </div>
  );
};

export default Marketing_Forcast_Page;
