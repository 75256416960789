import React, { useState } from "react";
import axios from "axios"; // Import Axios if not already imported

const Press_ReMat = () => {
  const [part_no, setPartNo] = useState("");
  const [part_name, setPartName] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [spec, setSpec] = useState("");
  const [department, setDepartment] = useState("");
  const [remark, setRemark] = useState("");
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!part_no || !quantity || !size) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("part_no", part_no);
      formData.append("part_name", part_name);
      formData.append("size", size);
      formData.append("spec", spec);
      formData.append("quantity", quantity);
      //formData.append("department", department);
      formData.append("remark", remark);

      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-received-log",
        formData
      );
      console.log("Status:", response.data.message);
      setIsOrderSubmitted(true);
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <div>
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Part No."
            type="text"
            value={part_no}
            onChange={(e) => setPartNo(e.target.value)}
            list="partNumbers" // Referencing the datalist
          />
        </label>
        <datalist id="partNumbers">
          {/* Define your valid options as <option> elements */}
          <option value="14919 4JA0A" />
          <option value="14919 4KD0A" />
          <option value="16422 4JA0A" />
          <option value="16422 4JL9E" />
          <option value="16422 4KD0A" />
          <option value="25238 4KJ2A" />
          <option value="16422 4KD9E" />
          <option value="16423 4JA0A" />
          <option value="16423 4JL0A" />
          <option value="16423 4KD0A" />
          <option value="16426 4JL0A" />
          <option value="20851 4JM0A" />
          {/* Add more options as needed */}
        </datalist>
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Part Name"
            type="text"
            value={part_name}
            onChange={(e) => setPartName(e.target.value)}
            list="partName" // Referencing the datalist
          />
        </label>
        <datalist id="partName">
          {/* Define your valid options as <option> elements */}
          <option value="BRKT-HARN" />
          <option value="BRJT-FUEL FLTR" />
          {/* Add more options as needed */}
        </datalist>
        <p />
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Size"
            type="text"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            list="size" // Referencing the datalist
          />
        </label>
        <datalist id="size">
          {/* Define your valid options as <option> elements */}
          <option value="0.8x95x147" />
          <option value="0.8x92xCOIL" />
          <option value="2.6x320xCOIL" />
          <option value="2.6x162x274" />
          <option value="2.0x150xCOIL" />
          <option value="2.6x250xCOIL" />
          <option value="1.6x435xCOIL" />
          <option value="2.0x110xCOIL" />
          <option value="2.6x120xCOIL" />
          <option value="2.6x335xCOIL" />
          <option value="2.6x328xCOIL" />
          <option value="2.6x120xCOIL" />
          {/* Add more options as needed */}
        </datalist>

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Quantity"
            type="text"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </label>
        <p />
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Spec"
            type="text"
            value={spec}
            onChange={(e) => setSpec(e.target.value)}
            list="spec" // Referencing the datalist
          />
        </label>
        <datalist id="spec">
          {/* Define your valid options as <option> elements */}
          <option value="SP122BQ" />
          <option value="SP121BQ" />
          <option value="SP781-440BQ" />
          <option value="SP221PQ" />
          {/* Add more options as needed */}
        </datalist>
        {/*    <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Department"
            type="text"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
          />
        </label>*/}

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Remark"
            type="text"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </label>

        <p />
      </div>
      <button type="submit">Submit Log</button>
      {isOrderSubmitted && <p>Log submitted successfully!</p>}
    </form>
  );
};

export default Press_ReMat;
