import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";

const Marketing_Forcast_Add = () => {
  const [part_no, setPartNo] = useState("");
  const [country_no, setCountryNo] = useState("");
  const [ran_no, setRanNo] = useState("");
  const [quantity, setQuantity] = useState("");
  const [delivery_date, setSelectedDate] = useState("");
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString()
  );
  const [isValidPart, setIsValidPart] = useState(false);
  const [validatingPartNo, setValidatingPartNo] = useState(false);
  const [initialOrderIds, setInitialOrderIds] = useState([]);

  const fetchOrderIds = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-marketing-list"
      );
      const ids = response.data.data.map((item) => item.order_id);
      setInitialOrderIds(ids);
    } catch (error) {
      console.error("Error fetching order IDs:", error);
      setInitialOrderIds([]);
    }
  };

  useEffect(() => {
    fetchOrderIds();
  }, []);

  const generateOrderId = (existingIds = [], recentIds = []) => {
    existingIds = Array.isArray(existingIds) ? existingIds : [];
    recentIds = Array.isArray(recentIds) ? recentIds : [];

    console.log("Existing Order IDs:", existingIds);
    console.log("Recent Order IDs:", recentIds);

    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    let nextSequentialNumber = 1;

    // Combine existingIds and recentIds to check for uniqueness
    const allIds = [...existingIds, ...recentIds];

    // Find the maximum sequential number from all order IDs
    const maxSequentialNumber = allIds.reduce((max, orderId) => {
      const sequentialNumber = parseInt(orderId.slice(-3));
      console.log("Sequential Number:", sequentialNumber);
      return Math.max(max, sequentialNumber);
    }, 0);

    console.log("Max Sequential Number:", maxSequentialNumber);

    // Increment the sequential number
    nextSequentialNumber = isNaN(maxSequentialNumber)
      ? 1
      : maxSequentialNumber + 1;

    let orderId = `OR${year}${month}${day}${nextSequentialNumber
      .toString()
      .padStart(3, "0")}`;

    // Check if the generated orderId already exists
    while (allIds.includes(orderId)) {
      console.log("Generated order ID already exists. Regenerating...");
      nextSequentialNumber++;
      orderId = `OR${year}${month}${day}${nextSequentialNumber
        .toString()
        .padStart(3, "0")}`;
    }

    return orderId;
  };

  const validatePartNumber = useCallback(async (part_no) => {
    setValidatingPartNo(true);
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-bom-list"
      );
      console.log("Validation API response:", response.data);
      console.log("Part number being validated:", part_no);

      if (
        Array.isArray(response.data.data) &&
        response.data.data.some((item) => item.part_no === part_no)
      ) {
        setIsValidPart(true);
        console.log("Part number is valid.");
      } else {
        setIsValidPart(false);
        console.log("Part number is invalid.");
        alert("Invalid part number.");
      }
    } catch (error) {
      console.error("Error validating part number:", error);
      setIsValidPart(false);
      alert("Error validating part number. Please try again.");
    } finally {
      setValidatingPartNo(false);
    }
  }, []);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function (...args) {
      const context = this;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const debouncedValidatePartNumber = useCallback(
    debounce(validatePartNumber, 3000),
    [validatePartNumber]
  );

  const handlePartNoChange = (e) => {
    const newPartNo = e.target.value;
    setPartNo(newPartNo);
    setIsValidPart(false); // Reset validation state when part number changes
    if (newPartNo.trim() !== "") {
      debouncedValidatePartNumber(newPartNo);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!part_no || !quantity || !delivery_date || !isValidPart) {
      alert(
        "Please fill out all required fields and ensure the part number is valid."
      );
      return;
    }

    try {
      const formData = new FormData();
      formData.append("part_no", part_no);
      formData.append("country_no", country_no);
      formData.append("ran_no", ran_no);
      formData.append("quantity", quantity);
      formData.append("delivery_date", delivery_date);
      formData.append("currentDate", currentDate);
      const orderId = generateOrderId([...initialOrderIds]); // Pass initialOrderIds here
      formData.append("order_id", orderId);

      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-marketing",
        formData
      );
      console.log("Status:", response.data.message);
      setIsOrderSubmitted(true);

      // Update initialOrderIds state with the new order ID
      setInitialOrderIds((prevIds) => [...prevIds, orderId]);
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <form method="post" onSubmit={handleSubmit}>
      <div>
        <p>Current Date: {currentDate}</p>
        <label>
          <input
            className="box_sign_input_order"
            placeholder="Enter PART NO."
            type="text"
            id="part_no"
            value={part_no}
            onChange={handlePartNoChange}
            list="partNumbers" // Referencing the datalist
          />
        </label>
        <datalist id="partNumbers">
          {/* Define your valid options as <option> elements */}
          <option value="16419 4JA0A" />
          <option value="16419 4JL0A" />
          <option value="16419 4KD0A" />
          {/* Add more options as needed */}
        </datalist>
        {validatingPartNo && <p>Validating part number...</p>}
        {!isValidPart && part_no && !validatingPartNo && (
          <p>Invalid part number.</p>
        )}

        <label>
          <input
            className="box_sign_input_order"
            placeholder="Enter country number"
            type="text"
            id="country_no"
            value={country_no}
            onChange={(e) => setCountryNo(e.target.value)}
          />
        </label>
        <p />
        <label>
          <input
            className="box_sign_input_order"
            placeholder="Enter RAN number"
            type="text"
            id="ran_no"
            value={ran_no}
            onChange={(e) => setRanNo(e.target.value)}
          />
        </label>

        <label>
          <input
            className="box_sign_input_order"
            placeholder="Enter quantity"
            type="text"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </label>
        <p />
        <label htmlFor="delivery_date"> Delivery date </label>
        <input
          className="box_sign_input_order"
          type="date"
          name="delivery_date"
          value={delivery_date}
          onChange={handleDateChange}
        />
      </div>
      <button type="submit" disabled={!isValidPart || validatingPartNo}>
        Submit Order
      </button>
      {isOrderSubmitted && <p> submitted successfully!</p>}
    </form>
  );
};

export default Marketing_Forcast_Add;
