import React, { useState } from "react";
import Planning_menu from "../../Component/Planning/Planning_Menu";
import Planning_Order_Mat from "../../Component/Planning/Planning_Order_Mat";
import Modal_order from "../../Component/Marketing/Modal_order";
import Bill_Mat_Table from "../../Component/Planning/Planning_StockMat_table";

const Planning_Stock = () => {
  const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);
  const [isExportModalOpen, setExportModalOpen] = useState(false);

  const openAddOrderModal = () => {
    setAddOrderModalOpen(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpen(false);
  };

  const openExportModal = () => {
    setExportModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };

  return (
    <div style={{ display: "flex" }}>
      <Planning_menu style={{ flex: "0 0 auto" }} />{" "}
      <div style={{ flex: "1" }}>
        <h1>Stock Material</h1>

        <div className="table_background">
          <Bill_Mat_Table />
        </div>
        <button onClick={openAddOrderModal}>Bill Mat</button>
      </div>
      <Modal_order
        isOpen={isAddOrderModalOpen}
        onClose={closeAddOrderModal}
        title="ADD ORDER"
      >
        <Planning_Order_Mat />
      </Modal_order>
    </div>
  );
};

export default Planning_Stock;
