// Home.js
import React from "react";
import Store_table from "../../Component/Store/Store_Table";

import Admin_menu from "../../Component/Admin/Admin_menu";

const Admin_Prod_Data = () => {
  return (
    <div style={{ display: "flex" }}>
      <Admin_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>STOCK MAT DATA</h1>
        <div className="table_background">
          <Store_table />
        </div>
      </div>
    </div>
  );
};

export default Admin_Prod_Data;
