// Home.js
import React from "react";
import Store_table from "../../Component/Store/Store_Table";
import Admin_menu from "../../Component/Admin/Admin_menu";
import Admin_BOM_List from "../../Component/Admin/Admin_BOM_List";
import Admin_Upload_BOM from "../../Component/Admin/Addmin_BOM_Upload";

const Admin_BOM_Page = () => {
  return (
    <div style={{ display: "flex" }}>
      <Admin_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>BUILD OF MATERIAL</h1>
        <div className="table_background">
          {" "}
          <div className="stay_left_upload">
            <Admin_Upload_BOM />
          </div>
          <Admin_BOM_List />
        </div>
      </div>
    </div>
  );
};

export default Admin_BOM_Page;
