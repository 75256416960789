import React, { useState } from "react";
import Planning_menu from "../../Component/Planning/Planning_Menu";
import Modal_order from "../../Component/Marketing/Modal_order";
import Planning_Order_Mat from "../../Component/Planning/Planning_Order_Mat";
import Bill_Mat_Table from "../../Component/Planning/Planning_Bill_Mat_Recept_Table";

const Bill_Mat = () => {
  const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);

  const openAddOrderModal = () => {
    setAddOrderModalOpen(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpen(false);
  };
  return (
    <div style={{ display: "flex" }}>
      <Planning_menu style={{ flex: "0 0 auto" }} />{" "}
      <div style={{ flex: "1" }}>
        <h1>RECEIPT MATERIAL</h1>

        <div className="table_background">
          <Bill_Mat_Table />
        </div>
        <button onClick={openAddOrderModal}>Bill Mat</button>
      </div>
      <Modal_order
        isOpen={isAddOrderModalOpen}
        onClose={closeAddOrderModal}
        title="ADD ORDER"
      >
        <Planning_Order_Mat />
      </Modal_order>
    </div>
  );
};

export default Bill_Mat;
