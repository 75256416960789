import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

function Planning_menu() {
  return (
    <div className="LeftMenu">
      <ul>
        <Link to="/HUB" className="menu-link">
          <li>HOME</li>
        </Link>
        <Link to="/planning" className="menu-link">
          <li>PLANNING</li>
        </Link>
        <p />
        <Link to="/planningfinishgood" className="menu-link">
          <li>FINISH GOOD</li>
        </Link>
        <Link to="/StockMat" className="menu-link">
          <li>STOCK MATERIAL</li>
        </Link>
        <p />
        <Link to="/BillMat" className="menu-link">
          <li>BILL MAT</li>
        </Link>
        <Link to="/PlanAssign" className="menu-link">
          <li>PLAN ASSIGN</li>
        </Link>
      </ul>
    </div>
  );
}

export default Planning_menu;
