import React, { useEffect } from "react";
import "./App.css";
import { isAuthenticated } from "./Component/AuthService";
import {
  HashRouter as Router,
  Routes,
  Route,
  BrowserRouter,
  Link,
  Navigate,
} from "react-router-dom";
import Fgmarketing from "./Page/Marketing/CheckFGMarketing";
import Marketing_page from "./Page/Marketing/Marketing";
import Report_Marketing from "./Page/Marketing/Report_marketing";
import TestAjex from "./Page/Test_ajex";
import Login from "./Page/Login";
import CustomerForm from "./Page/Add_Customer";
import Planning from "./Page/Planining/Planning";
import Planning_Stock from "./Page/Planining/Planning_StockMat";
import Bill_Mat from "./Page/Planining/Planning_BillMat";
import Planning_Plan_Assign from "./Page/Planining/Planning_Plan_Assign";
import Page_Hub from "./Page/Page_Hub";
import Planning_FG from "./Page/Planining/Planning_FG";
import Store from "./Page/Store/Store";
import Store_Withdraw from "./Page/Store/Store_Withdraw";
import Store_Recieve from "./Page/Store/Store_Recieve";
import Press_list from "./Page/Department/Press_List";
import Press_Daily_Prod from "./Page/Department/Press_Daily_Production";
import Press_Withdraw_log from "./Page/Department/Press_Withdraw_Log";
import Assy_Withdraw_log from "./Page/Department/Assy_Withdraw_Log";
import Assy_Daily_Prod from "./Page/Department/Assy_Daily_Production";
import Assy_list from "./Page/Department/Assy_List";
import FG_Marketing from "./Page/Finish Goods/FG_Marketing";
import FG_Page from "./Page/Finish Goods/FG_Page";
import FG_Plating from "./Page/Finish Goods/FG_Plating";
import FG_Receive from "./Page/Finish Goods/FG_Receive_Goods";
import Dashboard from "./Page/Admin/DashBoard";
import User_manage from "./Page/Admin/User_Manage";
import Admin_BOM_Page from "./Page/Admin/BOM";
import Admin_Prod_Data from "./Page/Admin/Production";
import Press_Return_Mat_log from "./Page/Department/Press_RollBack_Mat";
import Assy_Return_Mat_log from "./Page/Department/Assy_RollBack_Mat";
import Marketing_Forcast_Page from "./Page/Marketing/Marketing_Forcast";

const PrivateRoute = ({ element: Element, ...rest }) => {
  return isAuthenticated() ? <Element {...rest} /> : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <div className="App">
        <div className="Tab_nagata"> NAGATA (THAILAND) CO.,LTD </div>
        <header className="App-header">
          <Routes>
            <Route
              path="/fg"
              element={<PrivateRoute element={Fgmarketing} />}
            />
            <Route
              path="/marketing"
              element={<PrivateRoute element={Marketing_page} />}
            />
            <Route
              path="/marketingforcast"
              element={<PrivateRoute element={Marketing_Forcast_Page} />}
            />
            <Route
              path="/reportmarketing"
              element={<PrivateRoute element={Report_Marketing} />}
            />
            <Route
              path="/testFetch"
              element={<PrivateRoute element={TestAjex} />}
            />
            <Route
              path="/customer"
              element={<PrivateRoute element={CustomerForm} />}
            />

            <Route
              path="/planning"
              element={<PrivateRoute element={Planning} />}
            />
            <Route
              path="/BillMat"
              element={<PrivateRoute element={Bill_Mat} />}
            />
            <Route
              path="/PlanAssign"
              element={<PrivateRoute element={Planning_Plan_Assign} />}
            />
            <Route
              path="/StockMat"
              element={<PrivateRoute element={Planning_Stock} />}
            />
            <Route
              path="/planningfinishgood"
              element={<PrivateRoute element={Planning_FG} />}
            />
            <Route path="/Store" element={<PrivateRoute element={Store} />} />
            <Route path="/HUB" element={<PrivateRoute element={Page_Hub} />} />
            <Route path="/Login" element={<Login />} />
            <Route
              path="/Withdraw"
              element={<PrivateRoute element={Store_Withdraw} />}
            />
            <Route
              path="/Recieve"
              element={<PrivateRoute element={Store_Recieve} />}
            />

            <Route
              path="/PressJob"
              element={<PrivateRoute element={Press_list} />}
            />
            <Route
              path="/PressWithdraw"
              element={<PrivateRoute element={Press_Withdraw_log} />}
            />
            <Route
              path="/PressDaily"
              element={<PrivateRoute element={Press_Daily_Prod} />}
            />

            <Route
              path="/AssyJob"
              element={<PrivateRoute element={Assy_list} />}
            />
            <Route
              path="/AssyWithdraw"
              element={<PrivateRoute element={Assy_Withdraw_log} />}
            />
            <Route
              path="/AssyDaily"
              element={<PrivateRoute element={Assy_Daily_Prod} />}
            />

            <Route
              path="/FGPage"
              element={<PrivateRoute element={FG_Page} />}
            />
            <Route
              path="/FGPlating"
              element={<PrivateRoute element={FG_Plating} />}
            />
            <Route
              path="/FGMarketing"
              element={<PrivateRoute element={FG_Marketing} />}
            />
            <Route
              path="/FGReceive"
              element={<PrivateRoute element={FG_Receive} />}
            />

            <Route
              path="/Dashboard"
              element={<PrivateRoute element={Dashboard} />}
            />
            <Route
              path="/UserManage"
              element={<PrivateRoute element={User_manage} />}
            />
            <Route
              path="/BomData"
              element={<PrivateRoute element={Admin_BOM_Page} />}
            />
            <Route
              path="/ProdData"
              element={<PrivateRoute element={Admin_Prod_Data} />}
            />

            <Route
              path="/PressReturnMat"
              element={<PrivateRoute element={Press_Return_Mat_log} />}
            />
            <Route
              path="/AssyReturnMat"
              element={<PrivateRoute element={Assy_Return_Mat_log} />}
            />

            {/* If you want to prevent direct access to other routes */}
            <Route path="*" element={<Navigate to="/Login" replace />} />
          </Routes>
        </header>
        <div className="App-content">
          <div className="link_locate_fixed">
            {" "}
            {/*
            <Link to="/customer" className="menu-link">
              <li>Customer Add</li>
            </Link>
           
            <Link to="/planning" className="menu-link">
              <li>Planning Page</li>
            </Link>
            
            <Link to="/marketing" className="menu-link">
              <li>MARKETING</li>
            </Link>
            <Link to="/testFetch" className="menu-link">
              <li>Test</li>
            </Link>
            <Link to="/Login" className="menu-link">
              <li>Log in</li>
            </Link>
             */}
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
