import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal_order from "../../Component/Marketing/Modal_order";
import Depart_search from "./Depart_search_feat";

function Press_joblist() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    sortData();
  }, [data, searchTerm, selectedCategory]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-plan-assign-press-list"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortData = () => {
    const sorted = data.slice().sort((a, b) => {
      // Ensure job_no is not null or undefined
      if (!a.job_no || !b.job_no) return 0;

      // Extract year, month, day, and sequential number from job_no
      const regex = /JobPress(\d{2})(\d{2})(\d{2})(\d{3})/;
      const matchA = a.job_no.match(regex);
      const matchB = b.job_no.match(regex);

      // Ensure match is not null or undefined
      if (!matchA || !matchB) return 0;

      const [, yearA, monthA, dayA, seqA] = matchA;
      const [, yearB, monthB, dayB, seqB] = matchB;

      // Compare year, month, day, and sequential number
      if (yearA !== yearB) return parseInt(yearB) - parseInt(yearA); // Compare year in descending order
      if (monthA !== monthB) return parseInt(monthB) - parseInt(monthA); // Compare month in descending order
      if (dayA !== dayB) return parseInt(dayB) - parseInt(dayA); // Compare day in descending order
      return parseInt(seqB) - parseInt(seqA); // Compare sequential number in descending order
    });

    setSortedData(sorted);
    setCurrentPage(1); // Reset current page when sorting
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = sortedData.filter((item) => {
    if (selectedCategory === "all") {
      return Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return (
        (selectedCategory === "model" &&
          item.customer &&
          item.customer.model
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partName" &&
          item.customer &&
          item.customer.part_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partNo" &&
          item.customer &&
          item.customer.part_no
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      );
    }
  });
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (name, value) => {
    if (name === "selectedCategory") {
      setSelectedCategory(value);
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1); // Reset current page when search term changes
  };

  // Function to handle updating the remark for a specific item

  // Function to set the editable row ID when a row is clicked

  // Function to add a new item to currentItems

  return (
    <div>
      <div style={{ flex: "1" }}>
        <div className="table_background">
          <div className="search_render">
            <Depart_search
              selectedCategory={selectedCategory}
              searchTerm={searchTerm}
              onSearchChange={handleSearchChange}
            />
          </div>
          <table className="data-table">
            <thead>
              <tr>
                <th>Job No.</th>
                <th>Part No.</th>
                <th>Size</th>
                <th>Process</th>
                <th>Machine</th>
                <th>Tact Time</th>
                <th>Man Power</th>
                <th>Quantity</th>
                <th>Remark</th>
                {/* Add additional table headers as needed */}
              </tr>
            </thead>
            <tbody>
              {currentItems.reverse().map(
                (
                  item // Reverse the array here
                ) => (
                  <tr key={item.id}>
                    <td>{item.job_no}</td>
                    <td>{item.part_no}</td>
                    <td>{item.size}</td>
                    <td>{item.process}</td>
                    <td>{item.machine}</td>
                    <td>{item.tact_time}</td>
                    <td>{item.man_power}</td>
                    <td>{item.quantity}</td>
                    <td>{item.remarks}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          <div className="page_action">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="prev_order"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="next_order"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Press_joblist;
