// Login.js

import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { login } from "../Component/AuthService";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const loggedIn = await login(username, password);

      if (loggedIn) {
        setRedirect(true); // Redirect after successful login
      } else {
        setError("Failed to login. Please try again.");
      }
    } catch (error) {
      setError("Failed to login. Please try again.");
    }
  };

  if (redirect) {
    return <Navigate to="/HUB" />;
  }

  return (
    <div className="box_form_input">
      <h2 className="login_set">Login</h2>
      {error && <div>{error}</div>}
      <form onSubmit={handleSubmit}>
        <div>
          <p />
          <input
            className="box_sign_input"
            placeholder="Enter your username"
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <input
            className="box_sign_input"
            placeholder="Enter your password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <p />
        <button className="button_signin" type="submit">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
