// Home.js
import React from "react";
import FG_menu from "../../Component/Finish Goods/FG_Menu";
import FG_store from "../../Component/Finish Goods/FG_Store";

const FG_Page = () => {
  return (
    <div style={{ display: "flex" }}>
      <FG_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>FINISH GOODS</h1>
        <div className="table_background">
          <FG_store />
        </div>
      </div>
    </div>
  );
};

export default FG_Page;
