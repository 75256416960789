import React from "react";
import { Link } from "react-router-dom";
import "../Hub.css"; // Import CSS file
import myImage from "../images/marketing.jpg"; // Adjust the path as per your project structure
import planningImage from "../images/planning.png";
import storeimg from "../images/store.png";
import pressimg from "../images/Press.jpg";
import assyimg from "../images/Assy.png";
import fgimg from "../images/FG.png";
import Adminimg from "../images/admin.jpg";

const Page_Hub = () => {
  return (
    <div>
      <div className="image-links">
        <Link to="/marketing">
          {" "}
          <img
            src={myImage}
            alt="marketing"
            style={{
              width: "10%",
              height: "10%",
              borderRadius: "10%",
              margin: "3%",
            }}
          />
          <div
            style={{
              color: "white",
              top: "60%",
              marginLeft: "4%",
              position: "absolute",
            }}
          >
            Marketing
          </div>
        </Link>{" "}
        <Link to="/planning">
          {" "}
          <img
            src={planningImage}
            alt="planning"
            style={{
              width: "10%",
              height: "10%",
              borderRadius: "10%",
              margin: "3%",
            }}
          />
          <div
            style={{
              color: "white",
              top: "60%",
              marginLeft: "21.5%",
              position: "absolute",
            }}
          >
            Planning
          </div>
        </Link>{" "}
        <Link to="/Store">
          {" "}
          <img
            src={storeimg}
            alt="planning"
            style={{
              width: "10%",
              height: "10%",
              borderRadius: "10%",
              margin: "3%",
            }}
          />
          <div
            style={{
              color: "white",
              top: "60%",
              marginLeft: "39%",
              position: "absolute",
            }}
          >
            Store
          </div>
        </Link>{" "}
        <Link to="/PressJob">
          {" "}
          <img
            src={pressimg}
            alt="Press"
            style={{
              width: "10%",
              height: "10%",
              borderRadius: "10%",
              margin: "3%",
            }}
          />
          <div
            style={{
              color: "white",
              top: "60%",
              marginLeft: "56%",
              position: "absolute",
            }}
          >
            Press
          </div>
        </Link>{" "}
        <Link to="/AssyJob">
          {" "}
          <img
            src={assyimg}
            alt="Assy"
            style={{
              width: "10%",
              height: "10%",
              borderRadius: "10%",
              margin: "3%",
            }}
          />
          <div
            style={{
              color: "white",
              top: "60%",
              marginLeft: "72%",
              position: "absolute",
            }}
          >
            Assy
          </div>
        </Link>{" "}
        <Link to="/FGPage">
          {" "}
          <img
            src={fgimg}
            alt="Finish Goods"
            style={{
              width: "10%",
              height: "10%",
              borderRadius: "10%",
              margin: "3%",
            }}
          />
          <div
            style={{
              color: "white",
              top: "60%",
              marginLeft: "86.2%",
              position: "absolute",
            }}
          >
            Finish Goods
          </div>
        </Link>{" "}
        <Link to="/UserManage">
          {" "}
          <img
            src={Adminimg}
            alt="Admin"
            style={{
              width: "10%",
              height: "10%",
              borderRadius: "10%",
              margin: "3%",
            }}
          />
          <div
            style={{
              color: "white",
              top: "60%",
              marginLeft: "104.2%",
              position: "absolute",
            }}
          >
            Admin
          </div>
        </Link>{" "}
        {/* Add more image links as needed */}
      </div>
    </div>
  );
};

export default Page_Hub;
