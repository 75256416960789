import React, { useEffect, useState } from "react";

function Component() {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        // Make a GET request to the API endpoint
        const response = await fetch(
          "https://api-csi.chiangmaicode.com/api/get-account-all"
        );

        // Check if the response is successful (status code 200)
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        // Parse the JSON response
        const responseData = await response.json();

        // Update the state with the fetched data
        setData(responseData);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error.message);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []); // Run this effect only once when the component mounts

  return (
    <div>
      {data ? (
        <div>
          {/* Render the fetched data */}
          <h2>Data received:</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      ) : (
        <p>Loading...</p>
      )}
      {/*{data && (
        <ul>
          {data.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
          )}*/}
    </div>
  );
}

export default Component;
