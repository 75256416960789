import React, { useState } from "react";
import Press_menu from "../../Component/Department/Press_Menu";
import Press_Withdraw_Table from "../../Component/Department/Department_Press_Withdraw";

const Press_Withdraw_log = () => {
  return (
    <div style={{ display: "flex" }}>
      <Press_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>PRESS WITHDRAW LOG</h1>
        <div className="table_background">
          <Press_Withdraw_Table />
        </div>
      </div>
    </div>
  );
};

export default Press_Withdraw_log;
