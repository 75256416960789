// Home.js
import React from "react";
import Store_table from "../../Component/Store/Store_Table";
import FG_menu from "../../Component/Finish Goods/FG_Menu";

const FG_Receive = () => {
  return (
    <div style={{ display: "flex" }}>
      <FG_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>RECEIVE GOODS LOG</h1>
        <div className="table_background">
          <Store_table />
        </div>
      </div>
    </div>
  );
};

export default FG_Receive;
