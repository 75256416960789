import React, { useState, useEffect } from "react";
import axios from "axios"; // Import Axios if not already imported

const Planning_Assign_Assy_order = () => {
  const [part_no, setPartNo] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [remark, setRemark] = useState("");
  const [processive, setProcessive] = useState("");
  const [machine, setMachine] = useState("");
  const [man_power, setManPower] = useState("");
  const [delivery_date, setSelectedDate] = useState("");
  const [addsign_at, setAddSign] = useState("");
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);
  const [existingJobIds, setExistingJobIds] = useState([]);
  const [initialOrderIds, setInitialOrderIds] = useState([]);

  useEffect(() => {
    fetchExistingJobIds();
  }, []);

  const fetchExistingJobIds = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-plan-assign-assy-list"
      );
      const jobIds = response.data.data.map((item) => item.job_no);
      setExistingJobIds(jobIds);
      setInitialOrderIds(jobIds);
    } catch (error) {
      console.error("Error fetching existing Job IDs:", error);
      setExistingJobIds([]);
      setInitialOrderIds([]);
    }
  };

  const generateJobId = (existingIds = [], recentIds = []) => {
    existingIds = Array.isArray(existingIds) ? existingIds : [];
    recentIds = Array.isArray(recentIds) ? recentIds : [];

    console.log("Existing Job IDs:", existingIds);
    console.log("Recent Job IDs:", recentIds);

    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    let nextSequentialNumber = 1;

    // Combine existingIds and recentIds to check for uniqueness
    const allIds = [...existingIds, ...recentIds];

    // Find the maximum sequential number from all Job IDs
    const maxSequentialNumber = allIds.reduce((max, jobId) => {
      const sequentialNumber = parseInt(jobId.slice(-3));
      console.log("Sequential Number:", sequentialNumber);
      return Math.max(max, sequentialNumber);
    }, 0);

    console.log("Max Sequential Number:", maxSequentialNumber);

    // Increment the sequential number
    nextSequentialNumber = isNaN(maxSequentialNumber)
      ? 1
      : maxSequentialNumber + 1;

    let jobId = `JobAssy${year}${month}${day}${nextSequentialNumber
      .toString()
      .padStart(3, "0")}`;

    // Check if the generated jobId already exists
    while (allIds.includes(jobId)) {
      console.log("Generated Job ID already exists. Regenerating...");
      nextSequentialNumber++;
      jobId = `JobAssy${year}${month}${day}${nextSequentialNumber
        .toString()
        .padStart(3, "0")}`;
    }

    return jobId;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !part_no ||
      !quantity ||
      !processive ||
      !size ||
      !man_power ||
      !machine
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("part_no", part_no);
      formData.append("size", size);
      formData.append("process", processive);
      formData.append("machine", machine);
      formData.append("man_power", man_power);
      formData.append("quantity", quantity);
      formData.append("addsign_at", addsign_at);
      formData.append("remark", remark);
      const jobId = generateJobId([...initialOrderIds]); // Pass initialOrderIds here
      formData.append("job_no", jobId); // Append the generated Job ID to form data

      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-plan-assign-assy",
        formData
      );
      console.log("Status:", response.data.message);
      setIsOrderSubmitted(true);
      setInitialOrderIds((prevIds) => [...prevIds, jobId]);
      // Clear input fields after successful submission
    } catch (error) {
      console.error("Axios error:", error);
    }
  };

  const handleDateChange = (event) => {
    setAddSign(event.target.value);
  };
  return (
    <form method="post" onSubmit={handleSubmit}>
      <div>
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Part No."
            type="text"
            value={part_no}
            onChange={(e) => setPartNo(e.target.value)}
            list="partNumbers" // Referencing the datalist
          />
        </label>
        <datalist id="partNumbers">
          {/* Define your valid options as <option> elements */}
          <option value="14919 4JA0A" />
          <option value="14919 4KD0A" />
          <option value="16422 4JA0A" />
          <option value="16422 4JL9E" />
          <option value="16422 4KD0A" />
          <option value="25238 4KJ2A" />
          <option value="16422 4KD9E" />
          <option value="16423 4JA0A" />
          <option value="16423 4JL0A" />
          <option value="16423 4KD0A" />
          <option value="16426 4JL0A" />
          <option value="20851 4JM0A" />
          <option value="93470 4JA0A" />
          <option value="934E3 4JA0A" />
          {/* Add more options as needed */}
        </datalist>

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Size"
            type="text"
            value={size}
            onChange={(e) => setSize(e.target.value)}
            list="size" // Referencing the datalist
          />
        </label>
        <datalist id="size">
          {/* Define your valid options as <option> elements */}
          <option value="0.8x95x147" />
          <option value="0.8x92xCOIL" />
          <option value="2.6x320xCOIL" />
          <option value="2.6x162x274" />
          <option value="2.0x150xCOIL" />
          <option value="2.6x250xCOIL" />
          <option value="1.6x435xCOIL" />
          <option value="2.0x110xCOIL" />
          <option value="2.6x120xCOIL" />
          <option value="2.6x335xCOIL" />
          <option value="2.6x328xCOIL" />
          <option value="2.6x120xCOIL" />
          {/* Add more options as needed */}
        </datalist>
        <p />
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Quantity"
            type="text"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
        </label>

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Process"
            type="text"
            value={processive}
            onChange={(e) => setProcessive(e.target.value)}
            list="proc" // Referencing the datalist
          />
        </label>
        <datalist id="proc">
          {/* Define your valid options as <option> elements */}
          <option value="(2)FO,PI,BN-PI" />
          <option value="" />
          {/* Add more options as needed */}
        </datalist>
        <p />
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter machine"
            type="text"
            value={machine}
            onChange={(e) => setMachine(e.target.value)}
          />
        </label>
        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Man power"
            type="text"
            value={man_power}
            onChange={(e) => setManPower(e.target.value)}
          />
        </label>
        <p />

        <label>
          {" "}
          <input
            className="box_sign_input_order"
            placeholder="Enter Remark"
            type="text"
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
          />
        </label>
        <p />
        <label htmlFor="addsign_at"> Delivery date </label>
        <input
          className="box_sign_input_order"
          type="date"
          name="addsign_at"
          value={addsign_at}
          onChange={handleDateChange}
        />
      </div>
      <button type="submit">Assign Order</button>
      {isOrderSubmitted && <p>Assign submitted successfully!</p>}
    </form>
  );
};

export default Planning_Assign_Assy_order;
