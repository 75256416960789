import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Datatable.css"; // Import the CSS file
import SearchWithDropdown from "./Search"; // Import the SearchWithDropdown component

const DataTable = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortedData, setSortedData] = useState([]);
  const [editableRowId, setEditableRowId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    sortData();
  }, [data, searchTerm, selectedCategory]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-marketing-list"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortData = () => {
    const sorted = data.slice().sort((a, b) => {
      // Assuming 'recieve_order_date' is the property representing the date
      return new Date(b.recieve_order_date) - new Date(a.recieve_order_date);
    });
    setSortedData(sorted);

    setCurrentPage(1); // Reset current page when sorting
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = sortedData.filter((item) => {
    if (selectedCategory === "all") {
      return Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return (
        (selectedCategory === "model" &&
          item.customer &&
          item.customer.model
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partName" &&
          item.customer &&
          item.customer.part_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partNo" &&
          item.customer &&
          item.customer.part_no
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      );
    }
  });
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (name, value) => {
    if (name === "selectedCategory") {
      setSelectedCategory(value);
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1); // Reset current page when search term changes
  };

  // Function to handle updating the remark for a specific item

  const calculateTotalQuantities = () => {
    // Create an object to store totals
    const totals = {};

    // Iterate through currentItems to calculate totals
    currentItems.forEach((item) => {
      const key = `${item.part_no}  `; // Unique key for each combination of part_no and delivery_date

      // If totals[key] doesn't exist yet, initialize it to 0
      if (!totals[key]) {
        totals[key] = 0;
      }

      // Add the quantity of the current item to the total
      totals[key] += parseInt(item.quantity); // Parse quantity to ensure it's treated as a number
    });

    return totals;
  };

  return (
    <div>
      <div className="search_render">
        <SearchWithDropdown
          selectedCategory={selectedCategory}
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
      </div>
      <table className="data-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Receive Order Date</th>
            <th>Model</th>
            <th>Part No.</th>
            <th>Part Name</th>
            <th>RAN No.</th>
            <th>Country No.</th>
            <th>Quantity</th>
            <th>Delivery Date</th>
            <th>Destination</th>
            <th>Remark</th>
            {/* Add additional table headers as needed */}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id}>
              <td>{item.order_id}</td>
              <td>{item.recieve_order_date}</td>
              <td>{item.customer && item.customer.model}</td>
              <td>{item.part_no}</td>
              <td>{item.customer && item.customer.part_name}</td>
              <td>{item.ran_no}</td>
              <td>{item.country_no}</td>
              <td>{item.quantity}</td>
              <td>{item.delivery_date}</td>
              <td>{item.customer && item.customer.destination_country}</td>
              <td>{item.remark}</td>
            </tr>
          ))}
          <p />
          <div className="page_action">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="prev_order"
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="next_order"
            >
              Next
            </button>
          </div>
          <br />
          {/* Example of showing totals */}
          {Object.keys(calculateTotalQuantities()).map((key, index) => (
            <tr key={index}>
              <td colSpan="7">Total for {key}</td>
              <td>{calculateTotalQuantities()[key]}</td>
              {/* Colspan adjustment based on your table structure */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
