import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

const Admin_Upload_BOM = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const sendDataToServer = async (formData) => {
    try {
      const response = await axios.post(
        "https://api-csi.chiangmaicode.com/api/create-bom",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Data uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading data:", error);
    }
  };

  const handleFileUpload = async () => {
    try {
      const reader = new FileReader();
      reader.onload = async function (event) {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        const parsedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const results = [];

        for (let i = 1; i < parsedData.length; i++) {
          const row = parsedData[i];
          const partNo = row[0];
          const SubNo = row[1];
          const PartName = row[2];
          const quantity = row[3];

          // Generate a unique order ID for each row

          const formData = new FormData();
          formData.append("part_no", partNo);
          formData.append("sub_no", SubNo);
          formData.append("part_name", PartName);
          formData.append("quantity", quantity);

          results.push({
            index: i - 1,
            formData,
          });
          await sendDataToServer(formData);
        }
      };

      reader.readAsArrayBuffer(selectedFile);
    } catch (error) {
      console.error("Error processing file:", error);
    } finally {
    }
  };

  return (
    <div>
      <input type="file" accept=".xls, .xlsx" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>{"Upload Excel File"}</button>
    </div>
  );
};

export default Admin_Upload_BOM;
