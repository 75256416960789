import React from "react";

const FG_Search = ({
  selectedCategory,
  searchTerm,
  onSearchChange,
}) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    onSearchChange(selectedCategory, value);
  };

  const handleCategoryChange = (e) => {
    const { value } = e.target;
    onSearchChange("selectedCategory", value);
  };

  return (
    <div>
      <select value={selectedCategory} onChange={handleCategoryChange}>
        <option value="all">All</option>
        <option value="model">Model</option>
        <option value="partNo">Part No.</option>
        <option value="partName">Part Name</option>
      </select>
      <input
        type="text"
        placeholder={`Search ${
          selectedCategory === "all" ? "All" : selectedCategory
        }`}
        value={searchTerm}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default FG_Search;
