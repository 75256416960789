import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

function Press_menu() {
  return (
    <div className="LeftMenu">
      <ul>
        <Link to="/HUB" className="menu-link">
          <li>HOME</li>
        </Link>
        <p />
        <Link to="/PressJob" className="menu-link">
          <li>PRESS</li>
        </Link>
        <Link to="/PressWithdraw" className="menu-link">
          <li>WITHDRAW LOG</li>
        </Link>
        <Link to="/PressDaily" className="menu-link">
          <li>DAILY PRODUCTION</li>
        </Link>
        <Link to="/PressReturnMat" className="menu-link">
          <li>MAT RETURN</li>
        </Link>
      </ul>
    </div>
  );
}

export default Press_menu;
