// src/LeftMenu.js
import React from "react";
import "./Menu.css";
import { Link } from "react-router-dom";

function MenuMarketing() {
  return (
    <div className="LeftMenu">
      <ul>
        <Link to="/HUB" className="menu-link">
          <li>HOME</li>
        </Link>
        <Link to="/marketingforcast" className="menu-link">
          <li>MARKETING FORECAST</li>
        </Link>
        <Link to="/marketing" className="menu-link">
          <li>MARKETING</li>
        </Link>
        <p />
        <Link to="/fg" className="menu-link">
          <li>CHECK FG INVENTORY</li>
        </Link>
        <p />
        <Link to="/reportmarketing" className="menu-link">
          <li>Report</li>
        </Link>
      </ul>
    </div>
  );
}

export default MenuMarketing;
