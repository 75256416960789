import React, { useEffect, useState } from "react";
import axios from "axios";
import Planning_search from "./Planning_search";
import SearchResultsPopup from "./Planning_Search_Result";

const Planning_table = () => {
  const [bomData, setBOMData] = useState([]);
  const [marketingData, setMarketingData] = useState([]);
  const [stockData, setStockData] = useState({});
  const [currentDate, setCurrentDate] = useState(new Date());
  const [inputData, setInputData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResultsPopup, setShowSearchResultsPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bomResponse = await axios.get(
          "https://api-csi.chiangmaicode.com/api/get-bom-list"
        );
        const bomData = bomResponse.data.data;
        setBOMData(bomData);

        const marketingResponse = await axios.get(
          "https://api-csi.chiangmaicode.com/api/get-marketing-list"
        );
        const marketingData = marketingResponse.data.data;
        setMarketingData(marketingData);

        const stockResponse = await axios.get(
          "https://api-csi.chiangmaicode.com/api/get-finish-good-stock-list"
        );
        const stockData = stockResponse.data.data;

        const formattedStockData = stockData.reduce((acc, item) => {
          acc[item.part_no] = item.quantity;
          return acc;
        }, {});
        setStockData(formattedStockData); // Update stock data state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //const [selectedDate, setSelectedDate] = useState(new Date()); // State to store selected date
  const [selectedDate, setSelectedDate] = useState(null); // State to store selected date, initially set to null

  //console.log(selectedDate);

  const handleSearchChange = (category, term) => {
    setSelectedCategory(category);
    setSearchTerm(term);

    // Filter data based on search term, category, and selected date
    const filteredData = marketingData.filter((item) => {
      const itemDate = new Date(item.delivery_date);
      const selectedDateWithoutTime = selectedDate
        ? new Date(selectedDate)
        : null;

      if (selectedDateWithoutTime) {
        selectedDateWithoutTime.setHours(0, 0, 0, 0); // Remove the time portion
      }

      return (
        // Check if the part_no matches the search term and the delivery date matches the selected date
        (category === "part_no" &&
          typeof item[category] === "string" &&
          item[category].toLowerCase().includes(term.toLowerCase()) &&
          (!selectedDateWithoutTime ||
            itemDate.toDateString() ===
              selectedDateWithoutTime.toDateString())) ||
        // Check if the search term matches any property and the delivery date matches the selected date
        (category === "all" &&
          Object.values(item).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(term.toLowerCase())
          ) &&
          (!selectedDateWithoutTime ||
            itemDate.toDateString() ===
              selectedDateWithoutTime.toDateString())) ||
        // Check if the selected category matches and the delivery date matches the selected date
        (category !== "part_no" &&
          category !== "all" &&
          typeof item[category] === "string" &&
          item[category].toLowerCase().includes(term.toLowerCase()) &&
          (!selectedDateWithoutTime ||
            itemDate.toDateString() === selectedDateWithoutTime.toDateString()))
      );
    });

    // Update search results
    setSearchResults(filteredData);
    // Show search results popup
    setShowSearchResultsPopup(true);
  };

  // Function to handle changes in the date input
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Re-trigger search with the existing search term and the new selected date
    handleSearchChange(selectedCategory, searchTerm);
  };

  const filteredData = bomData.filter((item) => {
    if (selectedCategory === "all") {
      // Check if any value in the item matches the search term
      return Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      // Filter based on the selected category
      // Example: if selectedCategory is "part_no", you can filter based on item.part_no
      // Add specific filtering logic for each category if needed
      return true;
    }
  });

  const handleNext = () => {
    const nextDate = new Date(currentDate);
    nextDate.setDate(nextDate.getDate() + 31);
    setCurrentDate(nextDate);
  };

  const handlePrev = () => {
    const prevDate = new Date(currentDate);
    prevDate.setDate(prevDate.getDate() - 31);
    setCurrentDate(prevDate);
  };

  const days = Array.from({ length: 31 }, (_, i) => {
    const date = new Date(currentDate);
    date.setDate(date.getDate() + i);
    return date;
  });

  const getDeliveryDate = (day) => {
    const item = marketingData.find(
      (item) =>
        new Date(item.delivery_date).toDateString() === day.toDateString()
    );
    return item ? item.delivery_date : "";
  };

  const getQuantity = (partNo, day) => {
    // Find marketing data items with the specified part number and delivery date matching the current day
    const items = marketingData.filter(
      (item) =>
        item.part_no === partNo &&
        new Date(item.delivery_date).toDateString() === day.toDateString()
    );
    // Calculate the total quantity for the day
    const totalQuantity = items.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.quantity, 10),
      0
    );
    // Return the total quantity
    return totalQuantity;
  };

  useEffect(() => {
    const filteredBOMData = bomData.filter((item) => {
      if (selectedCategory === "all") {
        return Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTerm.toLowerCase())
        );
      } else {
        // Add specific filtering logic for each category if needed
        return true;
      }
    });

    const filteredMarketingData = marketingData.filter((item) => {
      // Apply similar filtering logic as above if needed
      return true;
    });

    //console.log("Filtered BOM Data:", filteredBOMData);
    //console.log("Filtered Marketing Data:", filteredMarketingData);

    // Update state with filtered data
    // You may want to store both filtered data in state if you plan to use them separately
  }, [bomData, marketingData, searchTerm, selectedCategory]);

  const getCurrentMonth = () => {
    const today = new Date();
    return today.getMonth(); // Adding 1 because getMonth returns 0-based index
  };

  const getNextMonths = () => {
    const currentMonth = getCurrentMonth();
    const nextMonth = currentMonth === 12 ? 1 : currentMonth + 1;
    const nextNextMonth = nextMonth === 12 ? 1 : nextMonth + 1;

    return [currentMonth, nextMonth, nextNextMonth];
  };
  const months = getNextMonths();

  const getQuantityByMonthAndPart = () => {
    const months = getNextMonths();
    const quantitiesByMonthAndPart = {};

    months.forEach((month) => {
      marketingData.forEach((item) => {
        const itemMonth = new Date(item.delivery_date).getMonth(); // Adding 1 for 1-based index
        if (itemMonth === month) {
          if (!quantitiesByMonthAndPart[month]) {
            quantitiesByMonthAndPart[month] = {};
          }
          if (!quantitiesByMonthAndPart[month][item.part_no]) {
            quantitiesByMonthAndPart[month][item.part_no] = 0;
          }
          quantitiesByMonthAndPart[month][item.part_no] += parseInt(
            item.quantity,
            10
          );
        }
      });
    });

    return quantitiesByMonthAndPart;
  };

  const displayQuantityByMonthAndPart = () => {
    const quantitiesByMonthAndPart = getQuantityByMonthAndPart();

    const months = getNextMonths();
    months.forEach((month) => {
      //console.log(`\n=== Month ${month} ===`);
      const quantities = quantitiesByMonthAndPart[month];
      for (const partNo in quantities) {
        // console.log(`Part No: ${partNo}, Quantity: ${quantities[partNo]}`);
      }
    });
  };

  displayQuantityByMonthAndPart();

  const getRemainingStock = (partNo, day) => {
    // Get stock quantity for the given part number from the updated stock data state
    const stockQuantity = stockData[partNo] || 0;
    // Calculate total quantity for the day using existing logic
    const totalQuantity = marketingData
      .filter(
        (item) =>
          item.part_no === partNo &&
          new Date(item.delivery_date).toDateString() === day.toDateString()
      )
      .reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.quantity, 10),
        0
      );
    // Calculate remaining stock by deducting total quantity from stock quantity
    const remainingStock = stockQuantity - totalQuantity;
    return remainingStock; // Allow negative stock
  };

  const renderTables = () => {
    const groupedItems = {};
    const quantitiesByMonthAndPart = getQuantityByMonthAndPart();

    bomData.forEach((item) => {
      if (!groupedItems[item.part_no]) {
        groupedItems[item.part_no] = [];
      }
      groupedItems[item.part_no].push(item);
    });

    return Object.keys(groupedItems).map((partNo, index) => {
      const items = groupedItems[partNo];
      return (
        <div key={index}>
          <h2>Part No : {partNo}</h2>
          <table className="data-table">
            <thead>
              <tr>
                <th>Part No.</th>
                <th>Part Name</th>
                <th>Finish Good</th>
                <th>Stock Store</th>
                {months.map((month, index) => (
                  <th key={index}>{getMonthName(month)}</th>
                ))}
                {days.map((day, index) => (
                  <th key={index}>
                    <div className="day-header" style={{ textAlign: "center" }}>
                      {day.toLocaleDateString()}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.map((item, itemIndex) => (
                <tr key={itemIndex}>
                  <td>{item.sub_no}</td>
                  <td>{item.part_name}</td>
                  <td>{item.finish_good}</td>
                  <td>{item.stock_store}</td>
                  {months.map((month, monthIndex) => (
                    <td key={monthIndex}>
                      {quantitiesByMonthAndPart[month] &&
                        quantitiesByMonthAndPart[month][partNo]}
                    </td>
                  ))}
                  {days.map((day, dayIndex) => (
                    <td key={dayIndex}>
                      {getQuantity(item.part_no, day)}{" "}
                      {/* /{" "}
                     {getRemainingStock(item.part_no, day)}*/}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    });
  };

  function getMonthName(monthIndex) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[monthIndex];
  }

  const handleCloseSearchResultsPopup = () => {
    setShowSearchResultsPopup(false); // Update state to hide the popup
  };

  return (
    <div>
      <div className="search_render">
        {" "}
        <input
          type="date"
          value={selectedDate ? selectedDate.toISOString().split("T")[0] : ""}
          onChange={(e) =>
            handleDateChange(e.target.value ? new Date(e.target.value) : null)
          }
        />
        <Planning_search
          selectedCategory={selectedCategory}
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange} // This function should handle only search changes
        />
        {showSearchResultsPopup && (
          <SearchResultsPopup
            results={searchResults}
            onClose={handleCloseSearchResultsPopup} // Separate function for closing the popup
          />
        )}
        <div className="page_action">
          <button onClick={handlePrev} className="next_week_button">
            Prev Month
          </button>
          <button onClick={handleNext} className="">
            Next Month
          </button>
        </div>
        {renderTables()}
      </div>
    </div>
  );
};

export default Planning_table;
