import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Datatable.css";
import Planning_search from "./Planning_search";

const Bill_Mat_Table = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    sortData();
  }, [data, searchTerm, selectedCategory]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-bill-material"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortData = () => {
    const sorted = data
      .slice()
      .sort((a, b) => {
        return new Date(b.recieve_order_date) - new Date(a.recieve_order_date);
      })
      .reverse(); // Reverse the order to show latest data first
    setSortedData(sorted);

    setCurrentPage(1); // Reset current page when sorting
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = sortedData.filter((item) => {
    if (selectedCategory === "all") {
      return Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      return (
        (selectedCategory === "model" &&
          item.customer &&
          item.customer.model
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partName" &&
          item.customer &&
          item.customer.part_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (selectedCategory === "partNo" &&
          item.customer &&
          item.customer.part_no
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      );
    }
  });
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (name, value) => {
    if (name === "selectedCategory") {
      setSelectedCategory(value);
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1); // Reset current page when search term changes
  };

  console.log()

  // Function to handle updating the remark for a specific item

  // Function to set the editable row ID when a row is clicked

  return (
    <div>
      <div className="search_render">
        <Planning_search
          selectedCategory={selectedCategory}
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
      </div>
      <table className="data-table">
        <thead>
          <tr>
            <th>Part No.</th>
            <th>Quantity</th>
            <th>Size</th>
            <th>Maker</th>
            <th>Order Date</th>
            <th>Recieved Date</th>
            <th>Remark</th>
            {/* Add additional table headers as needed */}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id}>
              <td>{item.part_no}</td>
              <td>{item.quantity}</td>
              <td>{item.size}</td>
              <td>{item.maker}</td>
              <td>{item.created_at}</td>
              <td>{item.recived_date}</td>
              <td>{item.remarks}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="page_action">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="prev_order"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="next_order"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Bill_Mat_Table;
