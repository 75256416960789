import axios from "axios";
import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const Marketing_export = () => {
  const ExcelJS = require("exceljs");
  const [currentItems, setCurrentItems] = useState({
    status: "",
    message: "",
    data: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api-csi.chiangmaicode.com/api/get-marketing-list"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setCurrentItems(result); // Assuming result is { status: 'Success', message: 'Success', data: [...] }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const exportToExcel = () => {
    try {
      // Check if data array exists and has items
      if (!Array.isArray(currentItems.data) || currentItems.data.length === 0) {
        throw new Error("No data to export");
      }

      // Create a new Excel workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Sheet1");

      // Define columns for worksheet (optional)
      worksheet.columns = [
        { header: "Order ID", key: "order_id" },
        { header: "Receive Order Date", key: "receive_order_date" },
        { header: "Customer Model", key: "customer_model" },
        { header: "Part Number", key: "part_number" },
        { header: "Part Name", key: "part_name" },
        { header: "Ran No", key: "ran_no" },
        { header: "Country No", key: "country_no" },
        { header: "Quantity", key: "quantity" },
        { header: "Delivery Date", key: "delivery_date" },
        { header: "Destination Country", key: "destination_country" },
        { header: "Remark", key: "remark" },
      ];

      // Add data to worksheet
      currentItems.data.forEach((item) => {
        worksheet.addRow({
          order_id: item.order_id,
          receive_order_date: item.receive_order_date,
          customer_model: item.customer ? item.customer.model : "",
          part_number: item.part_no,
          part_name: item.customer ? item.customer.part_name : "",
          ran_no: item.ran_no,
          country_no: item.country_no,
          quantity: item.quantity,
          delivery_date: item.delivery_date,
          destination_country: item.customer
            ? item.customer.destination_country
            : "",
          remark: item.remark,
        });
      });

      // Generate Excel file
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          saveAs(blob, "exported_data.xlsx");
        })
        .catch((error) => {
          console.error("Error exporting to Excel:", error);
        });
    } catch (error) {
      console.error("Error exporting to Excel:", error);
    }
  };

  if (currentItems.status !== "Success" || currentItems.data.length === 0) {
    return <div>Loading...</div>; // Add loading indicator while fetching data
  }

  return (
    <div>
      <button onClick={exportToExcel}>Export to Excel</button>
    </div>
  );
};

export default Marketing_export;
