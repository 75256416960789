import React, { useState } from "react";
import Press_menu from "../../Component/Department/Press_Menu";
import Modal_order from "../../Component/Marketing/Modal_order";
import Press_ReMat from "../../Component/Department/Press_Return_Mat_order";
import Press_RollBack_Mat_Table from "../../Component/Department/Press_RollBack_Mat";

const Press_Return_Mat_log = () => {
  const [isAddOrderModalLogOpen, setAddOrderModalLogOpen] = useState(false);

  const openAddOrderLogModal = () => {
    setAddOrderModalLogOpen(true);
  };

  const closeAddOrderLogModal = () => {
    setAddOrderModalLogOpen(false);
  };
  return (
    <div style={{ display: "flex" }}>
      <Press_menu style={{ flex: "0 0 auto" }} /> {/* Menu stays on the left */}
      <div style={{ flex: "1" }}>
        {" "}
        {/* Content stays on the right */}
        <h1>PRESS RETURN MAT LOG</h1>
        <div className="table_background">
          <Press_RollBack_Mat_Table />
        </div>
        <button onClick={openAddOrderLogModal}>ADD LOG</button>
      </div>
      <Modal_order
        isOpen={isAddOrderModalLogOpen}
        onClose={closeAddOrderLogModal}
        title="ADD LOG"
      >
        <Press_ReMat />
      </Modal_order>
    </div>
  );
};

export default Press_Return_Mat_log;
