// AuthService.js

import axios from "axios";

let authToken = null;

export const login = async (username, password) => {
  const formData = new FormData();
  formData.append("user_name", username);
  formData.append("pass_word", password);

  try {
    const response = await axios.post(
      "https://api-csi.chiangmaicode.com/api/login",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.data.status === "Success") {
      authToken = response.data.token; // Assuming your API returns a token
      localStorage.setItem("authToken", authToken); // Store token in localStorage
      return true;
    } else {
      throw new Error("Failed to login");
    }
  } catch (error) {
    throw new Error("Failed to login");
  }
};

export const logout = () => {
  authToken = null;
  localStorage.removeItem("authToken");
};

export const isAuthenticated = () => {
  authToken = localStorage.getItem("authToken");
  return authToken !== null;
};
