import React from "react";
import MenuMarketing from "../../Component/Marketing/Menu_Marketing";
import FG_store from "../../Component/Finish Goods/FG_Store";

const Fgmarketing = () => {
  return (
    <div style={{ display: "flex" }}>
      <MenuMarketing style={{ flex: "0 0 auto" }} />{" "}
      <div style={{ flex: "1" }}>
        <h1>FINISH GOOD INVENTORY</h1>

        <div className="table_background">
          <FG_store />
        </div>
      </div>
    </div>
  );
};

export default Fgmarketing;
