import React, { useState, useEffect } from "react";
import axios from "axios";
import Admin_search from "./Admin_search";
import Modal_order from "../Marketing/Modal_order";
import Admin_User_Add from "./Admin_User_Add";

const Admin_user_manage = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortedData, setSortedData] = useState([]);
  const [editableRowId, setEditableRowId] = useState(null);
  const [isAddOrderModalOpen, setAddOrderModalOpen] = useState(false);

  const openAddOrderModal = () => {
    setAddOrderModalOpen(true);
  };

  const closeAddOrderModal = () => {
    setAddOrderModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    sortData();
  }, [data, searchTerm, selectedCategory]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://api-csi.chiangmaicode.com/api/get-account-all"
      ); // Replace with your API endpoint
      setData(response.data); // Assuming response.data is an array of objects with the specified structure
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sortData = () => {
    const sorted = data.slice().sort((a, b) => {
      // Assuming you want to sort by account_id (modify as needed)
      return a.account_id.localeCompare(b.account_id);
    });
    setSortedData(sorted);
    setCurrentPage(1); // Reset current page when sorting
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredData = sortedData.filter((item) => {
    const searchText = Object.values(item).join(" ").toLowerCase();
    return searchText.includes(searchTerm.toLowerCase());
  });

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleSearchChange = (name, value) => {
    if (name === "selectedCategory") {
      setSelectedCategory(value);
    } else {
      setSearchTerm(value);
    }
    setCurrentPage(1); // Reset current page when search term changes
  };

  const handleRowClick = (accountId) => {
    setEditableRowId(accountId);
  };

  return (
    <div>
      <div className="search_render">
        <Admin_search
          selectedCategory={selectedCategory}
          searchTerm={searchTerm}
          onSearchChange={handleSearchChange}
        />
      </div>
      <table className="data-table">
        <thead>
          <tr>
            <th>Account ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            {/*<th>Profile Image</th>*/}
            {/*<th>Active</th>*/}
            <th>Username</th>
            <th>Password</th>
            <th>Account Type</th>
            {/*<th>Account role</th>*/}
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr
              key={item.account_id}
              onClick={() => handleRowClick(item.account_id)}
            >
              <td>{item.account_id}</td>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{item.email}</td>
              <td>{item.phone_number}</td>
              {/*}<td>
                <img src={item.img_profile} alt="Profile" />
              </td>*/}
              {/*} <td>{item.is_active}</td>*/}
              <td>{item.account_user}</td>
              <td>{item.account_pass}</td>
              <td>{item.account_type}</td>
              {/*<td>{item.account_role}</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="page_action">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="prev_order"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="next_order"
        >
          Next
        </button>
      </div>
      <div>
        <button onClick={openAddOrderModal}>Add User</button>
      </div>
      <Modal_order
        isOpen={isAddOrderModalOpen}
        onClose={closeAddOrderModal}
        title="ADD USER"
      >
        <Admin_User_Add />
      </Modal_order>
    </div>
  );
};

export default Admin_user_manage;
