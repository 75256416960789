import React, { useState, useEffect } from "react";
import axios from "axios";

const TotalSellModal = ({ isOpen, onClose, title }) => {
  const [totalSellData, setTotalSellData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (isOpen) {
      // Fetch data needed to calculate total sell from the backend API
      const fetchDataForTotalSell = async () => {
        try {
          // Fetch data needed for total sell calculation, for example, sales data for the month
          const salesDataResponse = await axios.get("/api/sales-data");
          const salesData = salesDataResponse.data;

          // Set total sell data
          setTotalSellData(salesData);
        } catch (error) {
          setError("Failed to fetch data for total sell calculation.");
        }
      };

      fetchDataForTotalSell();
    }
  }, [isOpen]);

  // Function to calculate total sell amount
  const calculateTotalSell = () => {
    // Perform calculations based on total sell data
    let total = 0;
    totalSellData.forEach((item) => {
      total += item.quantity; // Assuming each item object has a "quantity" property
    });
    return total;
  };

  return (
    <>
      {error && <div>{error}</div>}
      <div>Total Sell:</div>
      <ul>
        {totalSellData.map((item, index) => (
          <li key={index}>
            Part No: {item.partNo}, Quantity: {item.quantity}
          </li>
        ))}
      </ul>
      <div>Total Quantity: {calculateTotalSell()}</div>
    </>
  );
};

export default TotalSellModal;
