// src/components/Modal.js
import React from "react";
import "./Modal.css";

const Modal_order = ({ isOpen, onClose, title, children }) => {
  return (
    isOpen && (
      <div className="modal-overlay_order">
        <div className="modal_order">
          <button className="close-button-report" onClick={onClose}>
            &times;
          </button>
          <h2>{title}</h2>
          {children}
        </div>
      </div>
    )
  );
};

export default Modal_order;
